var themeDictionary = {
  "cust-theme-1.css": "assets/css/themes/cust-theme-1.css",
  "cust-theme-2.css": "assets/css/themes/cust-theme-2.css",
  "cust-theme-3.css": "assets/css/themes/cust-theme-3.css",
  "cust-theme-4.css": "assets/css/themes/cust-theme-4.css",
  "cust-theme-5.css": "assets/css/themes/cust-theme-5.css",
  "cust-theme-6.css": "assets/css/themes/cust-theme-6.css",
  "cust-theme-7.css": "assets/css/themes/cust-theme-7.css",
  "cust-theme-8.css": "assets/css/themes/cust-theme-8.css",
  "cust-theme-9.css": "assets/css/themes/cust-theme-9.css",
  "cust-theme-10.css": "assets/css/themes/cust-theme-10.css",
  "cust-theme-11.css": "assets/css/themes/cust-theme-11.css",
  "cust-theme-12.css": "assets/css/themes/cust-theme-12.css",
  "cust-theme-13.css": "assets/css/themes/cust-theme-13.css",
  "cust-theme-bradesco.css": "assets/css/themes/cust-theme-bradesco.css",
  "ten-theme.css": "assets/css/themes/ten-theme.css",
  "cust-theme-99.css": "assets/css/themes/cust-theme-99.css",
}

function displayToast(title, message, type) {
  const displayMethod = toastr[type];
  if (displayMethod) {
    displayMethod(message, title, {
      timeOut: 5000,
      closeButton: true,
      closeHtml: "<i class=\"fal fa-times\"></i>",
      closeMethod: 'fadeOut',
      closeDuration: 300,
      closeEasing: 'swing'
    });
  }
}

var meetingItems;

function changeTheme(scopeTheme) {
  if ($(myapp_config.mythemeAnchor).length) {
    $(myapp_config.mythemeAnchor).attr('href', themeDictionary[scopeTheme]);
  } else {
    var mytheme = $("<link>", {
      id: myapp_config.mythemeAnchor.replace('#', ''),
      "rel": "stylesheet",
      "href": (themeDictionary[scopeTheme] || '')
    });
    $('head').append(mytheme);
  }

  if ($(this).attr('data-themesave') != undefined) {

    initApp.saveSettings();
  }

  setTimeout(() => {
    let body = document.getElementById('indexBody');
    let video = document.getElementById('bgvid');
    if (body) {
      body.style.removeProperty('display');
    }

    if (video) {
      video.muted = true;
      video.play();
    }
  }, 1000);
}

/**
 * Start bootstrap tooltips
 **/
function startBootstrapTooltips() {
  if (typeof ($.fn.tooltip) !== 'undefined' && $('[data-toggle="tooltip"]').length) {
    $('[data-toggle="tooltip"]').tooltip(); /*{html: true}*/
  } else {
    console.log("OOPS! bs.tooltip is not loaded");
  }
}

function downloadFilePreservedName(documentURL, name) {
  $.ajax({
    url: documentURL,
    method: 'GET',
    xhrFields: {
      responseType: 'blob'
    },
    success: function (data) {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function () {
        var base64data = reader.result;
        const link = document.createElement('a');
        link.setAttribute('href', `data:${getMimeType(documentURL)};charset=utf-8;base64,${encodeURI(base64data.split(',')[1])}`);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    error: function (e) {
      console.log(e);
    }
  });
}

function downloadFile(documentURL) {
  $.ajax({
    url: documentURL,
    method: 'GET',
    xhrFields: {
      responseType: 'blob'
    },
    success: function (data) {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function () {
        var base64data = reader.result;
        var url = `data:application/pdf;base64,${encodeURI(base64data.split(',')[1])}`
        var win = window.open();
        win.document.write('<iframe src="' + url + '" frameborder="0" style="position: absolute; border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      }
    },
    error: function (e) {
      console.log(e);
    }
  });
}

function openPDFInPopup(documentURL) {
  //document.getElementById('dialog').style.display = 'block';
  $("#frame").attr("src", documentURL);
}

function closePDFInPopup(){
  document.getElementById('dialog').style.display = 'none';
}

function formatCNPJ(campo) {
  var v = campo.value;
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");
  v = v.substring(0, 18);
  campo.value = v;
}

function formatCPF(campo) {
  var v = campo.value;
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  v = v.substring(0, 14);
  campo.value = v
}

function goToBack() {
  $(document.scrollingElement || document.body).animate({
    scrollTop: document.body.scrollHeight
  }, 600);
}

function formatDecimal(inputDecimal) {
  const round = (number, decimalPlaces) => {
    const factorOfTen = Math.pow(10, decimalPlaces)
    return Math.round(number * factorOfTen) / factorOfTen
  };

  var value = $("#" + inputDecimal.id).val();
  value = value.replace(',', '.')
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*)\./g, '$1');
  value = round(value, 2);
  value = value > 100 ? '' : value;
  $("#" + inputDecimal.id).val(value);
}

window.openResourceLocalUrl = function (resourceUrl, mimeType) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', resourceUrl, true);
  xhr.responseType = 'arraybuffer';
  xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
          try {
              var blob = new Blob([xhr.response], { type: mimeType });
              // create an object URL from the Blob
              var URL = window.URL || window.webkitURL;
              var downloadUrl = URL.createObjectURL(blob);
              if (!isMobile()) {
                  var downloadLink = document.createElement('a');
                  downloadLink.target = '_blank';
                  downloadLink.href = downloadUrl;
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                  URL.revokeObjectURL(downloadUrl);
              }
              else {
                  window.top.location = downloadUrl;
              }
          } catch (e) {
              alert(e);
          }
      }
  };
  xhr.send();
}

window.isMobile = function () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

window.getMimeType = function(fileName) {
  if(fileName.toLowerCase().includes('.pdf')){
    return 'application/pdf';
  } else if (fileName.toLowerCase().includes('.csv')){
    return 'text/csv';
  } else if (fileName.toLowerCase().includes('.doc')){
    return 'application/msword';
  } else if (fileName.toLowerCase().includes('.docx')){
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  }

  return '';
}
